<template>
  <div id="matrix-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <BaseScrollbar
      class="q-pb-sm"
      :class="{
        'is-readonly': isReadonly,
        'is-disabled': isDisabled,
      }"
    >
      <table>
        <thead>
          <tr>
            <th></th>
            <th v-for="column in matrixColumns" :key="column.id">
              {{ column.label }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, idx) in matrixRows" :key="row.id">
            <th>{{ row.label }}</th>
            <td v-for="column in matrixColumns" :key="column.id">
              <!-- SHORT_TEXT -->

              <ShortTextFieldWrapper
                v-if="matrixType === 'SHORT_TEXT'"
                :key="column.id"
                v-model="matrixModel[idx][column.id]"
                :field="matrixTypeSettings"
              />

              <!-- ... -->

              <!-- SINGLE_SELECT -->

              <SingleSelectFieldWrapper
                v-if="matrixType === 'SINGLE_SELECT'"
                :key="column.id"
                v-model="matrixModel[idx][column.id]"
                :field="matrixTypeSettings"
              />

              <!-- ... -->

              <!-- CHECKBOX -->

              <ValidationProvider
                v-if="matrixType === 'CHECKBOX'"
                v-slot="{ errors }"
                :rules="{ required: validateChoices(idx) }"
              >
                <CheckboxField
                  v-model="matrixModel[idx][column.id]"
                  :error="errors[0]"
                />
              </ValidationProvider>

              <!-- ... -->

              <!-- RADIO -->

              <ValidationProvider
                v-if="matrixType === 'RADIO'"
                v-slot="{ errors }"
                :rules="{ required: validateChoices(idx) }"
              >
                <RadioField
                  :value="matrixModel[idx][column.id]"
                  :error="errors[0]"
                  @input="handleRadioInput(idx, column.id)"
                />
              </ValidationProvider>
            </td>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ShortTextFieldWrapper from "@/components/common/form/nested-fields/ShortTextFieldWrapper.vue";
import SingleSelectFieldWrapper from "@/components/common/form/nested-fields/SingleSelectFieldWrapper.vue";
import { cloneDeep } from "lodash-es";
import CheckboxField from "./components/CheckboxField.vue";
import RadioField from "./components/RadioField.vue";

export default {
  name: "MatrixField",

  components: {
    ValidationProvider,
    FormFieldLabel,
    FormFieldError,
    ShortTextFieldWrapper,
    SingleSelectFieldWrapper,
    CheckboxField,
    RadioField,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    matrixColumns: {
      type: Array,
      required: true,
    },

    matrixRows: {
      type: Array,
      required: true,
    },

    matrixType: {
      type: String,
      required: true,
    },

    matrixTypeSettings: {
      type: Object,
      required: true,
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      matrixModel: [],

      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _isMandatory() {
      return (
        this.isMandatory ||
        this.matrixTypeSettings.settings.validation.fieldRule === "REQUIRED"
      );
    },
  },

  watch: {
    matrixModel: {
      deep: true,
      handler() {
        this.$emit("input", this.matrixModel);
      },
    },

    matrixColumns: {
      deep: true,
      handler: "resetModel",
    },

    matrixRows: {
      deep: true,
      handler: "resetModel",
    },
  },

  created() {
    if (this.value.length) {
      this.matrixModel = cloneDeep(this.value);
      return;
    }

    this.matrixRows.forEach(() => this.addModel());
  },

  methods: {
    addModel() {
      const model = {};
      const defaultValue = ["CHECKBOX", "RADIO"].includes(this.matrixType)
        ? false
        : "";

      for (let column of this.matrixColumns) {
        model[column.id] = defaultValue;
      }

      this.matrixModel.push(model);
    },

    resetModel() {
      this.matrixModel = [];
      this.matrixRows.forEach(() => this.addModel());
    },

    handleRadioInput(rowIdx, columnId) {
      for (let column in this.matrixModel[rowIdx]) {
        this.matrixModel[rowIdx][column] = false;
      }

      this.matrixModel[rowIdx][columnId] = true;
    },

    validateChoices(rowIdx) {
      if (!this._isMandatory) {
        return false;
      }

      for (let column in this.matrixModel[rowIdx]) {
        if (this.matrixModel[rowIdx][column]) {
          return false;
        }
      }

      return true;
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#matrix-field {
  .is-readonly {
    pointer-events: none;
  }

  .is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th {
      padding: 4px 8px;
      font-weight: 500;
      text-transform: capitalize;
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      width: 158px;
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
