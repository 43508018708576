<template>
  <CalculatedField
    :value="value"
    :label="field.settings.general.hideLabel ? '' : field.label"
    transform=""
    :placeholder="placeholder"
    :tooltip="tooltip"
    :error="error"
    :class="size"
  />
</template>

<script>
import CalculatedField from "@/components/common/form/calculated-field/CalculatedField.vue";

export default {
  name: "CalculatedFieldWrapper",

  components: { CalculatedField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },
};
</script>

<style lang="scss" scoped></style>
