<template>
  <div id="chips-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      @clear="handleClear"
    >
      <div class="chips">
        <Draggable
          v-model="chips"
          class="full-width"
          :inner-style="{ display: 'flex', flexWrap: 'wrap', gap: '4px' }"
        >
          <div v-for="(chip, idx) in chips" :key="idx" class="chip">
            <div class="q-mr-sm">{{ chip }}</div>

            <BaseIcon
              name="eva-close"
              class="cursor-pointer"
              @click.stop="removeChip(idx)"
            />
          </div>

          <div key="input" style="flex: 1">
            <input
              v-model="newChip"
              type="text"
              :placeholder="chips.length ? '' : placeholder"
              style="height: 36px"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @keypress.enter="handleKeypress"
            />
          </div>
        </Draggable>
      </div>
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Draggable from "@/components/common/Draggable.vue";
import { cloneDeep, isEqual } from "lodash-es";

export default {
  name: "ChipsField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    Draggable,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      chips: [],
      newChip: "",
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value.length;
    },
  },

  watch: {
    chips: {
      deep: true,
      handler() {
        if (isEqual(this.value, this.chips)) {
          return;
        }

        this.$emit("input", this.chips);
      },
    },
  },

  created() {
    this.chips = cloneDeep(this.value);
  },

  methods: {
    removeChip(optionIdx) {
      this.chips.splice(optionIdx, 1);
    },

    handleKeypress() {
      if (!this.newChip) {
        return;
      }

      this.chips.push(this.newChip);
      this.newChip = "";
    },

    handleClear() {
      this.chips = [];
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#chips-field {
  .chips {
    display: flex;
    align-items: center;
    padding: 4px;
    min-height: 46px;

    .chip {
      font-weight: 500;
      background-color: var(--component-bg-color-inferior);
      padding: 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: move;
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
