<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <SignatureField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :max-file-size="maxFileSize"
      :error="errors[0]"
      @input="handleInput"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";

export default {
  name: "SignatureFieldWrapper",

  components: { ValidationProvider, SignatureField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    maxFileSize() {
      return this.field.settings.validation.maxFileSize;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
