<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <TimeField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :time-format="timeFormat"
      :error="errors[0] || customError"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "TimeFieldWrapper",

  components: { ValidationProvider, TimeField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      customError: "",
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      const range = this.field.settings.validation.timeRange;
      const maxiTime = this.field.settings.validation.maxiTime;
      const miniTime = this.field.settings.validation.miniTime;
      if (range === "TIME_RANGE") {
        const minTime = this.field.settings.validation.minimum;
        if (minTime) {
          rules.minTime = minTime;
        }

        const maxTime = this.field.settings.validation.maximum;
        if (maxTime) {
          rules.maxTime = maxTime;
        }
      } else if (range === "MINI_CURRENT_TIME") {
        if (maxiTime) {
          let time = this.$day.customFormat("hh:mm A");
          let add = time.split(":");
          let maximum = "";
          maximum = Number(add[0]) - maxiTime;

          // console.log(maximum);
          if (maximum <= 0) {
            let time = 12 + maximum;
            // console.log(time);
            rules.minTime = `${!maximum ? 12 : time}:${
              add[1].split(" ")[0]
            } AM`;
          } else {
            rules.minTime = `${maximum}:${add[1]}`;
          }
          // console.log(rules.minTime);
        } else {
          rules.minTime = this.$day.customFormat("hh:mm A");
        }
      } else if (range === "MAX_CURRENT_TIME") {
        if (miniTime) {
          let time = this.$day.customFormat("hh:mm A");
          let add = time.split(":");
          let maximum = "";
          maximum = Number(add[0]) + miniTime;

          // console.log(maximum);
          if (maximum >= 12) {
            let time = maximum - 12;
            // console.log(time);
            rules.maxTime = `${maximum == 12 ? 12 : time}:${
              add[1].split(" ")[0]
            } PM`;
          } else {
            rules.maxTime = `${maximum}:${add[1]}`;
          }
          // console.log(rules.maxTime);
        } else {
          rules.maxTime = this.$day.customFormat("hh:mm A");
        }
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    timeFormat() {
      return this.field.settings.validation.timeFormat;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.customError = "";
      if (this.field.settings.validation.timeRange === "CUSTOM_RANGE") {
        let minTime = this.field.settings.validation.minimum.split(":")[0];
        let maxTime = this.field.settings.validation.maximum.split(":")[0];
        let maxMins = this.field.settings.validation.maximum.split(":")[1];
        let hours = value.split(":")[0];
        let mins = value.split(":")[1];
        if (
          Number(hours) >= Number(minTime) ||
          Number(hours) < Number(maxTime)
        ) {
          //valid
        } else {
          if (
            Number(hours) < Number(minTime) &&
            Number(hours) > Number(maxTime)
          ) {
            this.customError = `should be greater than ${this.field.settings.validation.minimum}`;
          } else if (
            (Number(hours) === Number(maxTime) &&
              Number(mins) > Number(maxMins)) ||
            Number(hours) > Number(maxTime)
          ) {
            this.customError = `should be lesser than ${this.field.settings.validation.maximum}`;
          }
        }
      }
      this.$emit("input", value);
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (options.length === 1) {
            this.handleInput(options[0]);
          }
          this.$emit("clearFilter");
        } else {
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
