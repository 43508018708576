<template>
  <div id="date-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
      @clear="handleClear"
    >
      <div class="row items-center">
        <div class="col">
          <input
            v-imask="dateMask"
            type="text"
            :value="date"
            placeholder="YYYY-MM-DD"
            class="q-px-sm text"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @click.stop=""
            @accept="handleDateAccept"
            @complete="handleDateComplete"
          />
        </div>

        <BaseIcon
          name="eva-calendar-outline"
          class="cursor-pointer q-mr-sm"
          @click="toggleDatePicker"
        />

        <div class="col">
          <input
            v-imask="timeMask"
            type="text"
            :value="time"
            :placeholder="_placeholder"
            class="q-px-sm text"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @click.stop=""
            @accept="handleTimeAccept"
            @complete="handleTimeComplete"
          />
        </div>

        <BaseIcon
          name="eva-clock-outline"
          class="cursor-pointer q-mr-sm"
          @click="toggleTimePicker"
        />
      </div>

      <!-- date picker -->

      <q-menu
        v-model="datePicker"
        :target="$refs.wrapper"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <q-date
          minimal
          mask="YYYY-MM-DD"
          :value="value"
          emit-immediately
          @input="handleDateInput"
        />
      </q-menu>

      <!-- ... -->

      <!-- time picker -->

      <q-menu
        v-model="timePicker"
        :target="$refs.wrapper"
        anchor="bottom right"
        self="top right"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <TimePicker
          :value="time"
          :time-format="timeFormat"
          @input="handleTimeInput"
        />
      </q-menu>

      <!-- ... -->
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import { IMaskDirective, IMask } from "vue-imask";
import timeMask from "../time-field/time-mask.js";
import timeMask24 from "../time-field/time24-mask.js";

import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import TimePicker from "@/components/common/form/time-field/TimePicker.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

import { isValidDatetime } from "@/helpers/date-time-validator.js";

export default {
  name: "DateTimeField",

  components: { FormFieldLabel, FormFieldWrapper, TimePicker, FormFieldError },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    value: {
      type: String,
      default: "",
      validator: isValidDatetime,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    timeFormat: {
      type: String,
      default: "12",
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      datePicker: false,
      date: "",
      dateMask: {
        mask: Date,
        lazy: true,
        pattern: "Y-M-D",
        blocks: {
          D: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          M: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          Y: {
            mask: IMask.MaskedRange,
            from: 1000,
            to: 9999,
          },
        },
        format: function (date) {
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = "0" + month;

          return [year, month, day].join("-");
        },
        parse: function (str) {
          var yearMonthDay = str.split("-");
          return new Date(
            yearMonthDay[0],
            yearMonthDay[1] - 1,
            yearMonthDay[2]
          );
        },
      },
      timePicker: false,
      time: "",
      timeMask: {},
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _placeholder() {
      if (this.timeFormat === "12") {
        return "HH:MM AA";
      } else if (this.timeFormat === "24") {
        return "HH:MM";
      }
      return "HH:MM AA";
    },

    _isFocused() {
      return this.isFocused || this.datePicker || this.timePicker;
    },

    isClearable() {
      return !!this.value;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (!this.value) {
          return;
        }

        this.date = this.value.slice(0, 10);
        this.time = this.value.slice(11);
      },
    },

    timeMask: {
      immediate: true,
      handler() {
        if (this.timeFormat === "12") {
          this.timeMask = timeMask;
        } else {
          this.timeMask = timeMask24;
        }
      },
    },
  },

  methods: {
    toggleDatePicker() {
      this.datePicker = !this.datePicker;
    },

    toggleTimePicker() {
      this.timePicker = !this.timePicker;
    },

    handleDateInput(value) {
      this.date = value;
      this.handleEmit();
    },

    handleDateAccept(evt) {
      this.date = evt.detail.value;
      this.handleEmit();
    },

    handleDateComplete(evt) {
      this.date = evt.detail.value;
      this.handleEmit();
    },

    handleTimeInput(value) {
      this.time = value;
      this.handleEmit();
    },

    handleTimeAccept(evt) {
      this.time = evt.detail.value.toUpperCase();
      this.handleEmit();
    },

    handleTimeComplete(evt) {
      this.time = evt.detail.value.toUpperCase();
      this.handleEmit();
    },

    handleEmit() {
      const datetime = `${this.date} ${this.time}`;
      if (isValidDatetime(datetime, false)) {
        this.$emit("input", datetime);
      }
    },

    handleClear() {
      this.date = "";
      this.time = "";
      this.$emit("input", "");
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#date-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
