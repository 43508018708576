/* eslint-disable prettier/prettier */
export function validateFormula(formula) {
  //console.log(formula);
  const inputs = ["FIELD", "NUMBER"];
  const operators = ["PLUS", "MINUS", "MULTIPLY", "DIVIDE"];
  const parantheses = ["LEFT_PARANTHESES", "RIGHT_PARANTHESES"];

  for (let i = 0; i < formula.length; i++) {
    const previousToken = formula[i - 1];
    const token = formula[i];
    const nextToken = formula[i + 1];

    // field & number

    if (token.name === "FIELD" || token.name === "NUMBER") {
      if (
        previousToken &&
        ![...operators, ...parantheses].includes(previousToken.name)
      ) {
        //console.log(1);
        return true;
      }

      if (
        nextToken &&
        ![...operators, ...parantheses].includes(nextToken.name)
      ) {
        //console.log(2);
        return true;
      }

      if (!token.value) {
        //console.log(3);
        return true;
      }
    }

    // left parantheses

    if (token.name === "LEFT_PARANTHESES") {
      if (
        previousToken &&
        ![...operators, "LEFT_PARANTHESES"].includes(previousToken.name)
      ) {
        //console.log(4);
        return true;
      }

      if (
        nextToken &&
        nextToken.name !== "LEFT_PARANTHESES" &&
        !inputs.includes(nextToken.name)
      ) {
        // console.log("iteration:" + i);
        // console.log(nextToken);
        // console.log(inputs);
        // console.log(inputs.includes(nextToken.name));
        // console.log(5);
        return true;
      }
    }

    // right parantheses

    if (token.name === "RIGHT_PARANTHESES") {
      if (
        previousToken &&
        ![...inputs, "RIGHT_PARANTHESES"].includes(previousToken.name)
      ) {
        //console.log(6);
        return true;
      }

      if (
        nextToken &&
        nextToken.name !== "RIGHT_PARANTHESES" &&
        !operators.includes(nextToken.name)
      ) {
        // console.log("iteration:" + i);
        // console.log(nextToken);
        // console.log(operators);
        // console.log(operators.includes(nextToken.name));
        // console.log(7);
        return true;
      }
    }

    // operators

    if (operators.includes(token.name)) {
      if (
        !previousToken ||
        ![...inputs, "RIGHT_PARANTHESES"].includes(previousToken.name)
      ) {
        //console.log(8);
        return true;
      }

      if (
        !nextToken ||
        ![...inputs, "LEFT_PARANTHESES"].includes(nextToken.name)
      ) {
        //console.log(9);
        return true;
      }
    }
  }

  const leftParanthesesCount = formula.filter(
    (token) => token.name === "LEFT_PARANTHESES"
  ).length;
  const rightParanthesesCount = formula.filter(
    (token) => token.name === "RIGHT_PARANTHESES"
  ).length;

  if (leftParanthesesCount !== rightParanthesesCount) {
    //console.log(10);
    return true;
  }

  return false;
}

export function evaluateFormula(formula, model, fields, tableRows, rowIndex, fieldsModel, calculatedFieldsModel) {
  //console.log(formula);
  let timeCalculation = false;
  const _formula = formula.reduce((acc, cur) => {
    let valueFound = true;
    if (cur.name === "FIELD") {
      let fieldType = [];
      let sumOfValue = "";
      if (tableRows) {
        let value = cur.value.split("OF ");

        if (value.length === 2) {
          fieldType = fields.filter((field) => field.id === value[1]);
          sumOfValue = value[1];
        } else {
          fieldType = fields.filter((field) => field.id === cur.value);
        }
      } else {
        fieldType = fields.filter((field) => field.id === cur.value);
      }
      if (fieldType.length) {
        if (fieldType[0].type === "NUMBER" || fieldType[0].type === "COUNTER" || fieldType[0].type === "CALCULATED") {
          if (sumOfValue) {
            let sumTotal = 0;
            tableRows.forEach((sums, index) => {
              console.log(index);
              //if (index <= rowIndex) {
              sumTotal += Number(sums[sumOfValue]);
              //}
            });

            // if (
            //   tableRows[rowIndex] &&
            //   tableRows[rowIndex][sumOfValue] === model[sumOfValue]
            // ) {
            return `${acc} ${isFinite(sumTotal) ? sumTotal : 0}`;
            // }
          } else {
            if (fieldType[0].type === "CALCULATED") {
              if (calculatedFieldsModel[cur.value]) {
                return `${acc} ${isFinite(calculatedFieldsModel[cur.value]) ? calculatedFieldsModel[cur.value] : 0
                  }`;
              }
              else {
                return `${acc} 0`;
              }
            }
            else {
              if (model[cur.value]) {
                return `${acc} ${isFinite(model[cur.value]) ? model[cur.value] : 0
                  }`;
              } else if (typeof model[cur.value] === "object") {
                let colval = 0;
                Object.keys(model).forEach((key) => {
                  if (typeof model[key] === "object") {
                    model[key].filter((item) => {
                      if (item[cur.value]) {
                        colval += Number(item[cur.value]);
                      }
                      else {
                        valueFound = false;
                      }
                    });
                  }
                });
                if (valueFound) {
                  return `${acc} ${colval}`;
                }
              } else {
                return `${acc} 0`;
              }
            }

          }
        } else if (fieldType[0].type === "DATE") {
          if (model[cur.value]) {
            let days = new Date(model[cur.value]).getTime() / (1000 * 3600 * 24);
            return `${acc} ${days}`;
          } else {
            return `0`;
          }
        } else if (fieldType[0].type === "TIME") {
          timeCalculation = true;
          if (model[cur.value]) {
            let mins = 0;
            let formatSplit = model[cur.value].split(" ");
            let timeSplit = formatSplit[0].split(":");
            if (formatSplit[1] === "AM") {
              mins = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]);
            } else if (formatSplit[1] === "PM") {
              if (parseInt(timeSplit[0]) === 12) {
                mins = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]);
              } else {
                mins =
                  (parseInt(timeSplit[0]) + 12) * 60 + parseInt(timeSplit[1]);
              }
            } else {
              mins = parseInt(timeSplit[0]) * 60 + parseInt(timeSplit[1]);
            }
            return `${acc} ${mins}`;
          } else {
            return `0`;
          }
        } else if (fieldType[0].type === "DATE_TIME") {
          if (model[cur.value]) {
            let mins = new Date(model[cur.value]) / 60000;
            return `${acc} ${mins}`;
          } else {
            return `0`;
          }
        }
      } else if (tableRows) {
        if (fieldsModel[cur.value]) {
          return `${acc} ${isFinite(fieldsModel[cur.value]) ? fieldsModel[cur.value] : 0}`;
        }
        else {
          return `${acc} 0`;
        }
      } else {
        if (model[cur.value]) {
          return `${acc} ${isFinite(model[cur.value]) ? model[cur.value] : 0}`;
        } else if (typeof model[cur.value] === "object") {
          let colval = 0;
          Object.keys(model).forEach((key) => {
            if (typeof model[key] === "object") {
              model[key].forEach((item) => {
                if (item[cur.value]) {
                  colval += Number(item[cur.value]);
                } else {
                  valueFound = false;
                }
              });
            }
          });
          if (valueFound) {
            return `${acc} ${colval}`;
          }
        } else {
          let colval = 0;
          Object.keys(model).forEach((key) => {
            if (typeof model[key] === "object") {
              model[key].forEach((item) => {
                if (item[cur.value]) {
                  colval += Number(item[cur.value]);
                }
                // else {
                //   valueFound = false;
                // }
              });
            }
          });
          if (valueFound) {
            return `${acc} ${colval}`;
          }
          return `${acc} 0`;
        }
      }
    }
    if (valueFound) {
      return `${acc} ${cur.label}`;
    }
  }, "return ");

  try {
    //console.log(_formula);
    Function(_formula)();
  } catch (e) {
    return 0;
  }
  if (timeCalculation) {
    let value = Function(_formula)();
    if (value <= 0) {
      value += 24;
    }
    let decimal = (value - Math.floor(value)) * 60;
    return parseInt(value) + decimal / 100;
  }
  return Function(_formula)();
}
