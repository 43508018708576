<template>
  <div id="single-choice-field">
    <div class="row">
      <div class="col">
        <span
          v-if="!openLabelInput && label"
          class="label"
          @click="handleOpenInput"
        >
          <FormFieldLabel
            v-if="label"
            :label="label"
            :is-mandatory="isMandatory"
            :tooltip="tooltip"
            :transform="transform"
          />
        </span>

        <input
          v-if="openLabelInput"
          ref="focusMe"
          :value="label"
          type="text"
          autofocus
          class="label1"
          :class="!labelError ? 'q-mb-sm' : ''"
          @input="handleLabelInput"
          @mouseleave="handleFocusOut(label)"
        />

        <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />
      </div>
      <div class="col-auto">
        <template v-if="qrValue && !isReadonly">
          <div
            class="cursor-pointer"
            :class="[label ? '' : 'q-mb-sm']"
            @click.stop="scanQRCode"
          >
            <BaseIcon
              v-tooltip.top="'scan QR'"
              color="secondary"
              name="mdi-qrcode-scan"
            ></BaseIcon>
          </div>
        </template>
      </div>
    </div>

    <div
      class=""
      :class="{
        'is-readonly': isReadonly,
        'is-disabled': isDisabled,
        'options-wrapper': showOptionsWrapper,
      }"
    >
      <div class="row q-col-gutter-md">
        <div
          v-for="option in options"
          :key="option.id"
          :tabindex="0"
          class="option"
          :class="[
            _optionsPerLine,
            ,
            {
              'is-selected': value === option.value,
              'is-focused': isFocused === option.value,
            },
          ]"
          @focus="isFocused = option.value"
          @blur="isFocused = ''"
          @click="selectOption(option)"
          @keypress.space.stop="selectOption(option)"
        >
          <BaseIcon
            :name="
              value === option.value
                ? 'eva-radio-button-on'
                : 'eva-radio-button-off'
            "
            inherit-color
            class="icon"
            :class="[
              option.description || optionsPerLine === 1
                ? 'q-mr-md'
                : 'q-mr-sm',
            ]"
          />

          <BaseIcon
            v-if="option.icon"
            :name="option.icon"
            size="20px"
            :color="option.color"
            class="q-mr-sm"
          />

          <div>
            <div class="label">{{ option.label }}</div>
            <div v-if="option.description" class="description">
              {{ option.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "SingleChoiceField",

  components: { FormFieldLabel, FormFieldError },

  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    optionsPerLine: {
      type: Number,
      default: 3,
      validator(value) {
        return [0, 1, 2, 3, 4, 6].includes(value);
      },
    },

    showOptionsWrapper: {
      type: Boolean,
      default: true,
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: "",
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _optionsPerLine() {
      switch (this.optionsPerLine) {
        case 0:
          return !this.showOptionsWrapper ? "col-auto" : "col";
        case 2:
          return "col-6";
        case 3:
          return "col-4";
        case 4:
          return "col-3";
        case 6:
          return "col-2";
        default:
          return "col-12";
      }
    },
  },

  methods: {
    selectOption(option) {
      this.$emit("input", option.value);
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    scanQRCode() {
      this.$emit("scanQRCode");
    },
  },
};
</script>

<style lang="scss" scoped>
#single-choice-field {
  .options-wrapper {
    border-top: 1px solid var(--divider-color);
    padding-top: 16px;
  }

  .option {
    display: flex;
    align-items: center;
    outline: none;

    .label {
      color: var(--icon-color);
    }

    .icon {
      color: var(--icon-color-inverted);
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-top: 4px;
    }

    &:hover {
      cursor: pointer;

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-focused,
    &.is-selected {
      .icon {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .label {
        font-weight: 500;
        color: var(--body-text-color);
      }
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
