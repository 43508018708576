<template>
  <div id="select-field" class="cursor-pointer">
    <div class="row">
      <div class="col">
        <span
          v-if="!openLabelInput && label"
          class="label"
          @click="handleOpenInput"
        >
          <FormFieldLabel
            v-if="label"
            :label="label"
            :is-mandatory="isMandatory"
            :tooltip="tooltip"
            :transform="transform"
          />
        </span>

        <input
          v-if="openLabelInput"
          ref="focusMe"
          :value="label"
          type="text"
          autofocus
          class="label1"
          :class="!labelError ? 'q-mb-sm' : ''"
          @input="handleLabelInput"
          @blur="handleBlur"
          @mouseleave="handleFocusOut(label)"
        />

        <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />
      </div>
      <div class="col-auto">
        <template v-if="qrValue && !isReadonly">
          <div
            class="cursor-pointer"
            :class="[label ? '' : 'q-mb-sm']"
            @click.stop="scanQRCode"
          >
            <BaseIcon
              v-tooltip.top="'scan QR'"
              color="secondary"
              name="mdi-qrcode-scan"
            ></BaseIcon>
          </div>
        </template>
      </div>
    </div>

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :hide-border="hideBorder"
      :highlight="highlight"
      action-icon="eva-chevron-down"
      @click="showOptionPicker"
      @clear="handleClear"
    >
      <template #default>
        <!-- field -->

        <div class="select" :style="{ minHight: minHeight }">
          <!-- :style="{ minHight: minHeight }" -->
          <div v-if="_value" class="value">
            {{ _value }}
          </div>

          <input
            v-if="isSearchable && !_value"
            ref="searchQuery"
            v-model="searchQuery"
            type="text"
            :placeholder="_placeholder"
            class="q-px-sm"
            @focus="isFocused = true"
            @blur="
              {
                isFocused = false;
                handleBlur();
              }
            "
            @keypress.enter="handleKeypress"
            @input="handleSearch"
          />
        </div>

        <!-- ... -->

        <!-- option picker -->

        <q-menu
          v-model="optionPicker"
          :target="$refs.wrapper"
          fit
          no-focus
          no-refocus
          no-parent-event
          transition-show="scale"
          transition-hide="scale"
        >
          <OptionPicker
            :value="value"
            :options="_options"
            :new-option="newOption"
            @select="handleSelect"
          />
        </q-menu>

        <!-- ... -->
      </template>
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OptionPicker from "./OptionsPicker";
import { isEmpty, lowerCase, cloneDeep, isEqual } from "lodash-es";

export default {
  name: "SelectField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OptionPicker,
  },

  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: "",
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    isSearchable: {
      type: Boolean,
      default: true,
    },

    hideBorder: {
      type: Boolean,
      default: false,
    },

    minHeight: {
      type: String,
      default: "46px",
    },

    highlight: {
      type: String,
      default: "",
    },

    page: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      optionPicker: false,
      searchQuery: "",
      optionsClone: [],
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _placeholder() {
      if (this.value) {
        return "";
      }

      return this.placeholder || "Select";
    },

    _isFocused() {
      return this.isFocused || this.optionPicker;
    },

    _isClearable() {
      return this.isClearable && !!this._value;
    },

    _options() {
      if (!this.searchQuery) {
        return this.optionsClone;
      }

      if (this.$route.name === "sql-browse" && this.label === "Table Name") {
        return this.optionsClone;
      }

      return this.optionsClone.filter((option) =>
        lowerCase(option.label).includes(lowerCase(this.searchQuery))
      );
    },

    _value() {
      if (!this.value || isEmpty(this.optionsClone)) {
        return "";
      }

      const option = this.optionsClone.find(
        (option) => option.value === this.value
      );

      if (!option) {
        return "";
      }

      return option.label;
    },
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.options, this.optionsClone)) {
          if (this.value) {
            let optValue = this.options.find((row) => row.value === this.value);
            if (!optValue) {
              if (this.page === "repository") {
                this.options.push({
                  id: this.$nano.id(),
                  label: this.value,
                  value: this.value,
                });
              } else {
                this.$emit("input", "");
              }
            }
          }
          this.optionsClone = cloneDeep(this.options);
        }
      },
    },

    optionPicker() {
      if (!this.optionPicker) {
        this.searchQuery = "";
      }
    },
  },

  methods: {
    showOptionPicker() {
      this.optionPicker = true;
    },

    handleSelect(optionValue) {
      this.$emit("input", optionValue);
      this.optionPicker = false;
    },

    handleKeypress() {
      if (!this.searchQuery) {
        return;
      }

      if (this._options.length) {
        let optionIndex = this._options.find(
          (opt) => opt.value.toLowerCase() === this.searchQuery.toLowerCase()
        );
        if (optionIndex) {
          this.handleSelect(optionIndex.value);
          this.$refs.searchQuery.blur();
          return;
        }
      }

      if (this.newOption) {
        const newOption = {
          id: this.$nano.id(),
          label: this.searchQuery,
          value: this.searchQuery,
        };
        this.optionsClone.push(newOption);

        this.handleSelect(this.searchQuery);
        this.searchQuery = "";
        this.$refs.searchQuery.blur();
      }
      this.$emit("enter");
    },

    handleClear() {
      this.$emit("input", "");
    },

    handleSearch() {
      this.$emit("search", this.searchQuery);
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleBlur() {
      this.$emit("onBlur", this.value);
    },

    scanQRCode() {
      this.$emit("scanQRCode");
    },
  },
};
</script>

<style lang="scss" scoped>
#select-field {
  .select {
    min-height: 46px;
    display: flex;
    align-items: center;

    .value {
      white-space: nowrap;
      font-weight: 500;
      padding-left: 8px;
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
