<template>
  <div id="number-field">
    <div class="row">
      <div class="col">
        <span
          v-if="!openLabelInput && label"
          class="label"
          @click="handleOpenInput"
        >
          <FormFieldLabel
            v-if="label"
            :label="label"
            :is-mandatory="isMandatory"
            :tooltip="tooltip"
            :transform="transform"
          />
        </span>

        <input
          v-if="openLabelInput"
          ref="focusMe"
          :value="label"
          type="text"
          autofocus
          class="label1"
          :class="!labelError ? 'q-mb-sm' : ''"
          @input="handleLabelInput"
          @mouseleave="handleFocusOut(label)"
        />

        <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />
      </div>
      <div class="col-auto">
        <template v-if="qrValue && !isReadonly">
          <div
            class="cursor-pointer"
            :class="[label ? '' : 'q-mb-sm']"
            @click.stop="scanQRCode"
          >
            <BaseIcon
              v-tooltip.top="'scan QR'"
              color="secondary"
              name="mdi-qrcode-scan"
            ></BaseIcon>
          </div>
        </template>
      </div>
    </div>

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :highlight="highlight"
      class="q-px-sm"
      @clear="handleClear"
    >
      <input
        type="number"
        :value="value"
        class="text"
        :placeholder="placeholder"
        @focus="isFocused = true"
        @blur="
          {
            isFocused = false;
            handleBlur();
          }
        "
        @input.stop="handleInput"
      />

      <template v-if="isSearchField" #search>
        <BaseSeparator is-vertical />
        <div class="search-btn" @click="updateFormSearch">
          <BaseIcon name="eva-search"></BaseIcon>
        </div>
      </template>
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "NumberField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    value: {
      type: [Number, String],
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    isSearchField: {
      type: Boolean,
      default: false,
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _isClearable() {
      if (!this.isClearable) {
        return false;
      }

      return !!this.value;
    },
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", Number(evt.target.value));
    },

    handleClear() {
      this.$emit("input", "");
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleBlur() {
      this.$emit("onBlur", this.value);
    },

    updateFormSearch() {
      this.$emit("updateFormSearch", this.value);
    },

    scanQRCode() {
      this.$emit("scanQRCode");
    },
  },
};
</script>

<style lang="scss" scoped>
#number-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }

  .search-btn {
    background-color: var(--body-bg-color);
    cursor: pointer;
    padding: 12px;
  }
}
</style>
