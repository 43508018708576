<template>
  <FormFieldLabel
    no-margin
    :label="field.settings.general.hideLabel ? '' : field.label"
    transform=""
    :is-mandatory="isMandatory"
    :tooltip="tooltip"
    :class="size"
  />
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "LabelFieldWrapper",

  components: { FormFieldLabel },

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },
  },
};
</script>

<style lang="scss" scoped></style>
