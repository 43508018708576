<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="{ required: !_isReadonly ? isMandatory : false }"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <MultipleChoiceField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :tooltip="tooltip"
      :error="errors[0]"
      :options="options"
      :options-per-line="optionsPerLine"
      :highlight="highlight"
      @input="handleInput"
      @verify="$emit('verify')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "MultipleChoiceFieldWrapper",

  components: { ValidationProvider, MultipleChoiceField },

  props: {
    value: {
      type: Array,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },

    enableControls: {
      type: Object,
      default: () => {},
    },

    visibleControls: {
      type: Array,
      default: () => [],
    },

    hideControls: {
      type: Array,
      default: () => [],
    },

    parentControl: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      selectedValue: [],
      mandatorySettings: [],
      readonlySettings: [],
    };
  },

  computed: {
    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    options1() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    optionsPerLine() {
      return this.field.settings.specific.optionsPerLine;
    },
  },

  watch: {
    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          } else if (this.field.settings.specific.repositoryId) {
            this.getRepositoryDataFilter(this.field);
          }
        }
      },
    },

    value: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.value.length) {
          this.selectedValue = [];
        }
      },
    },
  },

  created() {
    const optionsType = this.field.settings.specific.optionsType;
    if (optionsType === "CUSTOM") {
      this.options = this.getCustomOptions();
    } else if (optionsType === "MASTER") {
      this.getMasterEntries(
        this.field.settings.specific.masterFormId,
        this.field.settings.specific.masterFormColumn
      );
    }

    this.mandatorySettings = this.field.settings.validation.mandatorySettings;
    // console.log(this.mandatorySettings);
    if (this.mandatorySettings && this.mandatorySettings.length) {
      // if (this.value) {
      this.setMandatory(this.value, "immediate");
      // }
    }

    this.readonlySettings = this.field.settings.validation.readonlySettings;

    if (this.readonlySettings && this.readonlySettings.length) {
      this.setReadOnly(this.value, "immediate");
    }
  },

  methods: {
    handleInput(value, unSelectOption) {
      this.$emit("input", value);

      this.setMandatory(value);
      this.setReadOnly(value);

      if (this.parentControl) {
        this.$emit("filterBy", this.field);
      }

      if (this.enableControls) {
        if (Object.keys(this.enableControls).length) {
          if (this.enableControls.controls.length) {
            let index = this.enableControls.controls.findIndex(
              (row) => row.id === this.field.id
            );
            if (index > -1) {
              let showControls = this.visibleControls;
              if (!value.length) {
                showControls = [];
                this.$emit("update:hideControls", []);
              }

              // this.enableControls.controls[index].parentControls.forEach(
              //   (control) => {
              //     let isSame = value.find((item) => item === control.value);
              //     console.log(isSame);
              //     if (isSame) {
              //       if (this.selectedValue.indexOf(isSame) === -1) {
              //         this.selectedValue.push(isSame);
              //         showControls.push(...control.childControls);
              //         console.log(showControls, this.hideControls);
              //       }
              //     } else {
              //       // console.log(control.childControls, "child");
              //       control.childControls.forEach((child) => {
              //         console.log(showControls.indexOf(child));
              //         if (showControls.indexOf(child) > -1) {
              //           // console.log(child);
              //           this.hideControls.push(child);
              //           showControls.splice(showControls.indexOf(child), 1);
              //           console.log(showControls, this.hideControls);
              //         }
              //       });
              //       // console.log(showControls, this.hideControls);
              //     }
              //   }
              // );

              //console.log(unSelectOption, "unselect");

              unSelectOption.forEach((item) => {
                let control = this.enableControls.controls[
                  index
                ].parentControls.find((item1) => item === item1.value);
                // console.log(control);
                // console.log(showControls);
                control.childControls.forEach((child) => {
                  //console.log(showControls.indexOf(child));
                  if (showControls.indexOf(child) > -1) {
                    // console.log(child);
                    this.hideControls.push(child);
                    showControls.splice(showControls.indexOf(child), 1);
                    //console.log(showControls, this.hideControls);
                  }
                });
                if (this.selectedValue.indexOf(item) > -1) {
                  this.selectedValue.splice(
                    this.selectedValue.indexOf(item),
                    1
                  );
                }
              });
              value.forEach((item) => {
                let control = this.enableControls.controls[
                  index
                ].parentControls.find((item1) => item === item1.value);
                //console.log(control);
                if (control) {
                  if (this.selectedValue.indexOf(item) === -1) {
                    this.selectedValue.push(item);
                    showControls.push(...control.childControls);
                    // console.log(showControls, this.hideControls);
                  }
                }
              });

              // console.log(showControls, "12");
              this.$emit("update:hideControls", this.hideControls);
              this.$emit("updateVisibleControls", showControls);
            }
          }
        }
      }
    },

    getCustomOptions() {
      const separator =
        this.field.settings.specific.separateOptionsUsing === "NEWLINE"
          ? "\n"
          : ",";

      return this.field.settings.specific.customOptions
        .split(separator)
        .map((option) => ({
          id: this.$nano.id(),
          label: option.trim(),
          value: option.trim(),
        }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            this.$emit("input", [this.options[0].value]);
          }
          this.$emit("clearFilter");
        } else {
          this.options = [
            {
              id: this.$nano.id(),
              label: this.value,
              value: this.value,
            },
          ];
          this.$emit("input", [""]);
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    setMandatory(value, immediate) {
      // console.log(this.mandatorySettings, value);
      if (this.mandatorySettings) {
        if (this.mandatorySettings.length) {
          let hasControls = [];
          if (value.length) {
            for (let option of value) {
              // console.log(option);
              let controls = this.mandatorySettings.find(
                (control) => control.value.trim() === option.trim()
              );
              // console.log(controls);
              if (controls) {
                hasControls.push(...controls.controls);
              }
            }
          } else {
            this.$emit(
              "mandatoryControls",
              [],
              this.mandatorySettings,
              immediate,
              this.field.id
            );
          }
          // console.log(hasControls);
          this.$emit(
            "mandatoryControls",
            hasControls,
            this.mandatorySettings,
            immediate,
            this.field.id
          );
        }
      }
    },

    setReadOnly(value, immediate) {
      // console.log(this.readonlySettings, value);
      if (this.readonlySettings) {
        if (this.readonlySettings.length) {
          let hasControls = [];
          if (value.length) {
            for (let option of value) {
              let controls = this.readonlySettings.find(
                (control) => control.value.trim() === option.trim()
              );
              // console.log(controls, value);
              if (controls) {
                // this.$emit(
                //   "readonlyControls",
                //   controls.controls,
                //   this.readonlySettings
                // );
                hasControls.push(...controls.controls);
              } else {
                this.$emit(
                  "readonlyControls",
                  [],
                  this.readonlySettings,
                  immediate,
                  this.field.id
                );
              }
            }
          } else {
            this.$emit(
              "readonlyControls",
              [],
              this.readonlySettings,
              immediate,
              this.field.id
            );
          }
          // console.log(hasControls);
          this.$emit(
            "readonlyControls",
            hasControls,
            this.readonlySettings,
            immediate,
            this.field.id
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
