export default {
  watch: {
    formModel: {
      immediate: true,
      deep: true,
      handler() {
        this.fieldsModel = {};

        if (!this.panels.length) {
          return;
        }
        this.panels.forEach((panel) => {
          if (!panel.fields.length) {
            return;
          }
          for (let field of panel.fields) {
            if (field.type === "CALCULATED") {
              this.$set(
                this.calculatedFieldsModel,
                field.id,
                parseFloat(this.formModel[field.id]) || 0
              );
              //return;
            }

            if (
              !this.isStatelessField(field.type) &&
              field.type !== "CALCULATED"
            ) {
              if (
                this.formSecureControls.indexOf(panel.id) < 0 &&
                this.formSecureControls.indexOf(field.id) < 0
              ) {
                this.$set(
                  this.fieldsModel,
                  field.id,
                  this.getDefaultValue(field)
                );
              } else {
                this.$set(this.fieldsModel, field.id, "");
              }
            }
          }
        });
      },
    },
  },

  methods: {
    getEmptyDefaultValue(fieldType) {
      switch (fieldType) {
        case "MULTI_SELECT":
        case "MULTIPLE_CHOICE":
        case "FILE_UPLOAD":
        case "IMAGE_UPLOAD":
        case "TABLE":
        case "MATRIX":
        case "CHIPS":
        case "NESTED_LIST":
          return [];
        case "COUNTER":
        case "CALCULATED":
        case "RATING":
          return 0;
        case "FILL_IN_THE_BLANKS":
        case "POPUP":
        case "TABS":
          return {};
        default:
          return "";
      }
    },

    getDefaultValue(field) {
      const defaultValue = field.settings.specific.defaultValue;

      if (defaultValue === "USER_EMAIL") {
        return this.$store.state.session.email;
      }

      if (defaultValue === "USER_NAME") {
        let name = "";
        if (this.$store.state.session.firstName) {
          name = this.$store.state.session.firstName;
        }
        if (this.$store.state.session.lastName) {
          name += " " + this.$store.state.session.lastName;
        }
        return name;
      }

      if (defaultValue === "LOGIN_NAME") {
        let name = "";
        if (this.$store.state.session.loginName) {
          name = this.$store.state.session.loginName;
        }
        return name;
      }

      if (defaultValue === "CURRENT_DATE") {
        return this.$day.newDate();
      }

      if (defaultValue === "CURRENT_TIME") {
        return this.$day.newTime();
      }

      if (defaultValue === "CURRENT_DATE_TIME") {
        return this.$day.newDateTime();
      }

      if (defaultValue === "AUTO_GENERATE") {
        const prefix = field.settings.specific.autoGenerateValue.prefix;
        const suffixFormat = field.settings.specific.autoGenerateValue.suffix;

        const suffixFormats = {
          DATE_TIME: "YYYYMMDDhhmmss",
          DATE: "YYYYMMDD",
          TIME: "hhmmss",
        };

        const suffix = this.$day.customFormat(suffixFormats[suffixFormat]);

        return `${prefix} - ${suffix}`;
      }
      if (defaultValue === "CUSTOM") {
        if (!this.formModel || Object.keys(this.formModel).length === 0) {
          if (field.type === "FILE_UPLOAD") {
            return [];
          }
          if (field.type === "SINGLE_SELECT") {
            if (
              field.settings.specific.optionsType === "PREDEFINED" ||
              field.settings.specific.optionsType === "MASTER" ||
              field.settings.specific.optionsType === "REPOSITORY"
            ) {
              if (
                field.settings.specific.defaultValueInSelectField ===
                "USER_EMAIL"
              ) {
                return this.$store.state.session.email;
              }

              if (
                field.settings.specific.defaultValueInSelectField ===
                "USER_NAME"
              ) {
                let name = "";
                if (this.$store.state.session.firstName) {
                  name = this.$store.state.session.firstName;
                }
                if (this.$store.state.session.lastName) {
                  name += " " + this.$store.state.session.lastName;
                }
                return name;
              }
              if (
                field.settings.specific.defaultValueInSelectField ===
                "LOGIN_NAME"
              ) {
                let name = "";
                if (this.$store.state.session.loginName) {
                  name = this.$store.state.session.loginName;
                }
                return name;
              }
            }
          }
          return (
            field.settings.specific.customDefaultValue ||
            this.getEmptyDefaultValue(field.type)
          );
        }
      }

      if (defaultValue === "MASTER" && field.type === "TABLE") {
        if (!this.formModel || Object.keys(this.formModel).length === 0)
          return (
            field.settings.specific.customDefaultValue ||
            this.getEmptyDefaultValue(field.type)
          );
      }

      return "";
    },

    isStatelessField(fieldType) {
      return ["LABEL", "DIVIDER", "PARAGRAPH"].includes(fieldType);
    },
  },
};
