<template>
  <div id="date-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <div class="row item-center">
        <FormFieldLabel
          v-if="label"
          :label="label"
          :is-mandatory="isMandatory"
          :tooltip="tooltip"
          :transform="transform"
        />
        <q-chip
          v-if="prediction"
          outline
          color="pink"
          size="sm"
          class="q-ml-sm"
          style="margin: 0px 0px 0px 10px"
        >
          <q-avatar
            icon="mdi-auto-fix"
            class="q-mr-sm"
            color="pink"
            text-color="white"
          />
          {{ prediction }}
        </q-chip>
      </div>
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      :hide-border="hideBorder"
      :highlight="highlight"
      action-icon="eva-calendar-outline"
      @click="toggleDatePicker"
      @clear="handleClear"
    >
      <input
        v-imask="dateMask"
        type="text"
        :value="date"
        :placeholder="_placeholder"
        class="q-px-sm text"
        @focus="isFocused = true"
        @blur="
          {
            isFocused = false;
            handleBlur();
          }
        "
        @click.stop=""
        @accept="handleAccept"
        @complete="handleComplete"
      />

      <!-- date picker -->

      <q-menu
        v-model="datePicker"
        :target="$refs.wrapper"
        no-refocus
        no-parent-event
        transition-show="scale"
        transition-hide="scale"
      >
        <q-date
          minimal
          mask="YYYY-MM-DD"
          :value="value"
          today-btn
          :options="validationOptions"
          @input="handleInput"
        >
        </q-date>
      </q-menu>

      <!-- ... -->
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
    <FormFieldError
      v-if="prediction"
      error="The uploaded document has expired"
    />
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import dateMask from "@/components/common/form/date-field/date-mask.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { isValidDate } from "@/helpers/date-validator.js";

export default {
  name: "DateField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    value: {
      type: String,
      default: "",
      validator: isValidDate,
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    hideBorder: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    emitImmediately: {
      type: Boolean,
      default: true,
    },

    validation: {
      type: Boolean,
      default: false,
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    currentDate: {
      type: Boolean,
      default: false,
    },

    minimumValidation: {
      type: Boolean,
      default: false,
    },

    maximumValidation: {
      type: Boolean,
      default: false,
    },

    maximumDate: {
      type: Number,
      default: 0,
    },

    minimumDate: {
      type: Number,
      default: 0,
    },

    dateRangeValidation: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: () => {},
    },

    prediction: {
      type: String,
      default: "",
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      datePicker: false,
      date: "",
      dateMask: dateMask,
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    _placeholder() {
      return this.placeholder || "YYYY-MM-DD";
    },

    _isFocused() {
      return this.isFocused || this.datePicker;
    },

    isClearable() {
      return !!this.value;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.date = this.value;
        this.datePicker = false;
      },
    },
  },

  methods: {
    toggleDatePicker() {
      this.datePicker = !this.datePicker;
    },

    handleInput(value) {
      // console.log(value);

      this.$emit("input", value);
    },

    handleAccept(evt) {
      this.date = evt.detail.value;
    },

    handleComplete(evt) {
      this.$emit("input", evt.detail.value);

      this.datePicker = false;
    },

    handleClear() {
      this.$emit("input", "");
    },

    validationOptions(date) {
      // console.log(date);
      if (this.validation) {
        let newDate = this.$day.newDate().replace(new RegExp("-", "g"), "/");
        return date >= newDate;
      } else if (this.minimumValidation) {
        if (this.maximumDate) {
          let miniDate = this.$day.newDate().replace(new RegExp("-", "g"), "/");
          let maxiDate = this.$day
            .addDate(this.$day.newDate(), this.maximumDate)
            .replace(new RegExp("-", "g"), "/");

          return date >= miniDate && date <= maxiDate;
        } else {
          let newDate = this.$day.newDate().replace(new RegExp("-", "g"), "/");
          return date >= newDate;
        }
      } else if (this.maximumValidation) {
        if (this.minimumDate) {
          let miniDate = this.$day
            .subtractDate(this.$day.newDate(), this.minimumDate)
            .replace(new RegExp("-", "g"), "/");
          let maxiDate = this.$day.newDate().replace(new RegExp("-", "g"), "/");

          return date >= miniDate && date <= maxiDate;
        } else {
          let newDate = this.$day.newDate().replace(new RegExp("-", "g"), "/");

          return date <= newDate;
        }
      } else if (this.dateRangeValidation) {
        let minimum = this.field.settings.validation.minimum;
        let maximum = this.field.settings.validation.maximum;
        const minDate = minimum.replace(new RegExp("-", "g"), "/");

        const maxDate = maximum.replace(new RegExp("-", "g"), "/");
        // console.log(minimum, maximum);
        if (minimum && maximum) {
          return date >= minDate && date <= maxDate;
        } else if (minimum) {
          return date >= minDate;
        } else if (maximum) {
          return date <= maxDate;
        }
      } else {
        return true;
      }
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },

    handleBlur() {
      this.$emit("onBlur", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#date-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
