<template>
  <div
    id="rating-field"
    :class="{ 'is-readonly': isReadonly, 'is-disabled': isDisabled }"
  >
    <FormFieldLabel
      v-if="label"
      :label="label"
      :is-mandatory="isMandatory"
      :tooltip="tooltip"
      :transform="transform"
    />

    <div :key="ratingIconCount" class="rating-icons">
      <RatingIcon
        v-for="n in ratingIconCount"
        :key="n"
        :label="n"
        :selected-value="value"
        :icon="ratingIcon"
        :color="color"
        :allow-half-rating="allowHalfRating"
        @input="handleInput"
      />
    </div>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import RatingIcon from "./RatingIcon.vue";

export default {
  name: "RatingField",

  components: { FormFieldLabel, FormFieldError, RatingIcon },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    ratingIconCount: {
      type: Number,
      default: 5,
    },

    ratingIcon: {
      type: String,
      required: true,
    },

    allowHalfRating: {
      type: Boolean,
      required: true,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
    };
  },

  computed: {
    color() {
      switch (this.ratingIcon) {
        case "HEART":
          return "red";
        case "SHIELD":
          return "green";
        default:
          return "amber";
      }
    },
  },

  methods: {
    handleInput(rating) {
      if (this.value === rating) {
        this.$emit("input", 0);
        return;
      }

      this.$emit("input", Number(rating));
    },

    handleClear() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
#rating-field {
  .rating-icons {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
}
</style>
