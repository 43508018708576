import { render, staticRenderFns } from "./MultipleChoiceField.vue?vue&type=template&id=f88cc952&scoped=true&"
import script from "./MultipleChoiceField.vue?vue&type=script&lang=js&"
export * from "./MultipleChoiceField.vue?vue&type=script&lang=js&"
import style0 from "./MultipleChoiceField.vue?vue&type=style&index=0&id=f88cc952&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88cc952",
  null
  
)

export default component.exports