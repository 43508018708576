<template>
  <div id="select-field" class="cursor-pointer">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      ref="wrapper"
      :is-focused="_isFocused"
      :is-clearable="_isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error || !!ruleError"
      :highlight="highlight"
      action-icon="eva-chevron-down"
      @click="showOptionPicker"
      @clear="handleClear"
    >
      <template #default>
        <!-- field -->

        <div class="select">
          <template v-if="_value.length > 1 && !isReadonly">
            <div class="chip">
              <div class="q-mr-sm">{{ _value.length }} item selected</div>
              <BaseIcon name="eva-close" @click.stop="handleClear" />
            </div>
          </template>
          <template v-else>
            <div v-for="(option, idx) in _value" :key="option" class="chip">
              <div class="q-mr-sm">{{ option }}</div>

              <BaseIcon name="eva-close" @click.stop="removeOption(idx)" />
            </div>
          </template>

          <div class="col search-query">
            <input
              ref="searchQuery"
              v-model="searchQuery"
              type="text"
              :placeholder="_placeholder"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @keypress.enter="handleKeypress"
            />
          </div>
        </div>

        <!-- ... -->

        <!-- option picker -->

        <q-menu
          v-model="optionPicker"
          :target="$refs.wrapper"
          fit
          no-focus
          no-refocus
          no-parent-event
          transition-show="scale"
          transition-hide="scale"
        >
          <OptionPicker
            is-multiple
            :value="value"
            :options="_options"
            :new-option="newOption"
            :select-filter="searchQuery ? true : false"
            @select="handleSelect"
            @selectAllFilter="handleSelectAllFilter"
            @selectValues="handleSelectAll"
            @unselectValues="handleUnselectAll"
          />
        </q-menu>

        <!-- ... -->
      </template>
    </FormFieldWrapper>

    <template v-if="highlight === 'orange1'">
      <BaseButton
        label="Confirm"
        color="orange"
        class="q-mt-md"
        @click="$emit('verify')"
      />
    </template>

    <FormFieldError v-if="error" :error="error" />
    <FormFieldError v-if="ruleError" :error="ruleError" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import OptionPicker from "./OptionsPicker";
import { isEmpty, lowerCase, cloneDeep, isEqual } from "lodash-es";

export default {
  name: "MultiSelectField",

  components: {
    FormFieldLabel,
    FormFieldWrapper,
    FormFieldError,
    OptionPicker,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    error: {
      type: String,
      default: "",
    },

    newOption: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    emailRule: {
      type: Boolean,
      default: false,
    },

    domainRule: {
      type: Boolean,
      default: false,
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      optionPicker: false,
      searchQuery: "",
      optionsClone: [],
      openLabelInput: false,
      labelError: "",
      ruleError: "",
    };
  },

  computed: {
    _placeholder() {
      if (this.value.length) {
        return;
      }

      return this.placeholder || "Select";
    },

    _isFocused() {
      return this.isFocused || this.optionPicker;
    },

    _isClearable() {
      return this.isClearable && !!this.value.length;
    },

    _options() {
      if (!this.searchQuery) {
        //return this.optionsClone;
        let selectedOptionList = [];
        if (this.value) {
          selectedOptionList = this.value.reduce((row, optionValue) => {
            let value = this.optionsClone.find(
              (option) => option.value === optionValue
            );
            // console.log(value, optionValue, "value");
            if (value) {
              row.push(value);
            }
            return row;
          }, []);
        }
        let otherOptionList = this.optionsClone.filter(
          (option) => this.value.indexOf(option.value) < 0
        );
        return [...selectedOptionList, ...otherOptionList];
      }

      return this.optionsClone.filter((option) =>
        lowerCase(option.label).includes(lowerCase(this.searchQuery))
      );
    },

    _value() {
      if (isEmpty(this.value) || isEmpty(this.optionsClone)) {
        return [];
      }
      let data = [];
      data = this.value.reduce((row, optionValue) => {
        let value = this.optionsClone.find(
          (option) => option.value === optionValue
        )?.label;
        // console.log(value, optionValue, "value");
        if (value) {
          row.push(value);
        }
        return row;
      }, []);
      if (data.length) {
        return data;
      }
      return data;
      // return this.value.map(
      //   (optionValue) =>
      //     this.optionsClone.find((option) => option.value === optionValue)
      //       ?.label
      // );
    },
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.options, this.optionsClone)) {
          if (this.value) {
            this.value.forEach((option) => {
              let optValue = this.options.findIndex(
                (row) => row.value === option
              );
              if (optValue > -1) {
                // this.options.push({
                //   id: this.$nano.id(),
                //   label: option,
                //   value: option,
                // });
                this.$emit("input", this.value);
              } else {
                //this.value.splice(optValue, 1);
              }
            });
          }
          this.optionsClone = cloneDeep(this.options);
        }
      },
    },

    optionPicker() {
      if (!this.optionPicker) {
        this.searchQuery = "";
      }
    },

    searchQuery() {
      if (!this.searchQuery) {
        if (this.domainRule || this.emailRule) {
          this.ruleError = "";
        }
      }
    },
  },

  methods: {
    showOptionPicker() {
      this.optionPicker = true;
    },

    handleSelectAll(optionValues) {
      this.$emit("input", optionValues, []);
      this.searchQuery = "";
    },

    handleUnselectAll(optionValues) {
      this.$emit("input", [], optionValues);
      this.searchQuery = "";
    },

    handleSelect(optionValue) {
      const selectedOptions = [...this.value];

      const optionIdx = selectedOptions.findIndex(
        (_optionValue) => _optionValue === optionValue
      );

      let unSelectOption = [];

      if (optionIdx === -1) {
        selectedOptions.push(optionValue);
      } else {
        unSelectOption.push(optionValue);
        selectedOptions.splice(optionIdx, 1);
      }

      this.$emit("input", selectedOptions, unSelectOption);
      this.searchQuery = "";
    },

    removeOption(optionIdx) {
      const selectedOptions = [...this.value];
      selectedOptions.splice(optionIdx, 1);
      this.$emit("input", selectedOptions, [...this.value]);
    },

    handleKeypress() {
      if (!this.searchQuery) {
        return;
      }
      if (this.searchQuery.indexOf("*") > -1) {
        let criteriaValue = [];
        let search = this.searchQuery.replace("*", "");
        this._options.forEach((option) => {
          if (option.label.toLowerCase().indexOf(search.toLowerCase()) > -1) {
            criteriaValue.push(option.value);
          }
        });
        this.$emit("input", criteriaValue);
        this.searchQuery = "";
        return;
      }

      if (this._options.length) {
        let optionIndex = this._options.find(
          (opt) => opt.value.toLowerCase() === this.searchQuery.toLowerCase()
        );
        if (optionIndex) {
          this.handleSelect(optionIndex.value);
          this.$refs.searchQuery.blur();
          return;
        }
      }

      if (this.newOption) {
        if (this.domainRule) {
          const domainValidation = new RegExp(
            /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
            "i"
          );
          console.log(!domainValidation.test(this.searchQuery));
          if (!domainValidation.test(this.searchQuery)) {
            this.ruleError = "domain is not valid";
            return;
          } else {
            this.ruleError = "";
          }
        }

        if (this.emailRule) {
          const emailValidation = String(this.searchQuery)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          console.log(!emailValidation);
          if (!emailValidation) {
            this.ruleError = "email is not valid";
            return;
          } else {
            this.ruleError = "";
          }
        }
        const newOption = {
          id: this.$nano.id(),
          label: this.searchQuery,
          value: this.searchQuery,
        };
        this.optionsClone.push(newOption);

        this.handleSelect(this.searchQuery);
        this.$refs.searchQuery.blur();
      }
    },

    handleClear() {
      this.$emit("input", [], this.value);
    },

    handleSelectAllFilter() {
      let selectedOptions = [];
      this.optionsClone.forEach((option) => {
        if (lowerCase(option.label).includes(lowerCase(this.searchQuery))) {
          selectedOptions.push(option.value);
        }
      });
      this.$emit("input", selectedOptions);
      this.searchQuery = "";
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#select-field {
  .select {
    min-height: 46px;
    padding: 0px 8px 8px;
    display: flex;
    flex-wrap: wrap;

    .chip {
      font-weight: 500;
      background-color: var(--component-bg-color-inferior);
      padding: 4px 8px;
      border-radius: 4px;
      margin-top: 8px;
      margin-right: 8px;
      display: flex;
      align-items: center;
    }

    .search-query {
      margin-top: 8px;
      margin-right: 8px;

      input {
        min-width: 64px;
        height: 100%;
        padding: 0px 4px;
      }
    }
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
