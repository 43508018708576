<template>
  <div id="password-field">
    <span
      v-if="!openLabelInput && label"
      class="label"
      @click="handleOpenInput"
    >
      <FormFieldLabel
        v-if="label"
        :label="label"
        :is-mandatory="isMandatory"
        :tooltip="tooltip"
        :transform="transform"
      />
    </span>

    <input
      v-if="openLabelInput"
      ref="focusMe"
      :value="label"
      type="text"
      autofocus
      class="label1"
      :class="!labelError ? 'q-mb-sm' : ''"
      @input="handleLabelInput"
      @mouseleave="handleFocusOut(label)"
    />

    <FormFieldError v-if="labelError" :error="labelError" class="q-mb-sm" />

    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="isClearable"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      class="q-px-sm"
      :action-icon="showPassword ? 'eva-eye-off-outline' : 'eva-eye-outline'"
      @action="togglePassword"
      @clear="handleClear"
    >
      <input
        :type="type"
        :value="value"
        :placeholder="placeholder"
        class="text"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input.stop="handleInput"
        @keypress.enter="$emit('enter')"
      />
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";

export default {
  name: "PasswordField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    value: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    labelEdit: {
      type: Boolean,
      default: false,
    },

    panels: {
      type: Array,
      default: () => [],
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      showPassword: false,
      openLabelInput: false,
      labelError: "",
    };
  },

  computed: {
    type() {
      return this.showPassword ? "text" : "password";
    },

    isClearable() {
      return !!this.value;
    },
  },

  methods: {
    handleInput(evt) {
      this.$emit("input", evt.target.value);
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    handleClear() {
      this.showPassword = false;
      this.$emit("input", "");
    },

    handleFocusOut(label) {
      if (!label || this.labelError) {
        return;
      }
      this.openLabelInput = false;
    },

    handleLabelInput(evt) {
      let label = evt.target.value;
      this.labelError = "";
      let labelFound = false;
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.label.toLowerCase() === label.toLowerCase()) {
            labelFound = true;
            return;
          }
        }
      });
      if (labelFound) {
        this.labelError = "Specified label already assigned";
      }
      this.$emit("update:label", evt.target.value);
    },

    handleOpenInput() {
      if (this.labelEdit) {
        this.openLabelInput = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#password-field {
  input.text {
    min-height: 46px;
  }

  .label1 {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25rem;
  }

  .label:hover {
    cursor: auto;
  }
}
</style>
