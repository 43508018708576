var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"multiple-choice-field"}},[(!_vm.openLabelInput && _vm.label)?_c('span',{staticClass:"label",on:{"click":_vm.handleOpenInput}},[(_vm.label)?_c('FormFieldLabel',{attrs:{"label":_vm.label,"is-mandatory":_vm.isMandatory,"tooltip":_vm.tooltip,"transform":_vm.transform}}):_vm._e()],1):_vm._e(),(_vm.openLabelInput)?_c('input',{ref:"focusMe",staticClass:"label1",class:!_vm.labelError ? 'q-mb-sm' : '',attrs:{"type":"text","autofocus":""},domProps:{"value":_vm.label},on:{"input":_vm.handleLabelInput,"mouseleave":function($event){return _vm.handleFocusOut(_vm.label)}}}):_vm._e(),(_vm.labelError)?_c('FormFieldError',{staticClass:"q-mb-sm",attrs:{"error":_vm.labelError}}):_vm._e(),_c('div',{class:{
      'is-readonly': _vm.isReadonly,
      'is-disabled': _vm.isDisabled,
      'options-wrapper': _vm.showOptionsWrapper,
    }},[_c('div',{staticClass:"row q-col-gutter-md"},_vm._l((_vm.options),function(option){return _c('div',{key:option.id,staticClass:"option",class:[
          _vm._optionsPerLine,
          {
            'is-selected': _vm.isSelected(option),
            'is-focused': _vm.isFocused === option.value,
          },
        ],attrs:{"tabindex":0},on:{"focus":function($event){_vm.isFocused = option.value},"blur":function($event){_vm.isFocused = ''},"click":function($event){return _vm.selectOption(option)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();return _vm.selectOption(option)}}},[_c('BaseIcon',{staticClass:"icon",class:[
            option.description || _vm.optionsPerLine === 1
              ? 'q-mr-md'
              : 'q-mr-sm',
          ],attrs:{"name":_vm.isSelected(option)
              ? 'mdi-checkbox-marked'
              : 'mdi-checkbox-blank-outline',"inherit-color":""}}),_c('div',[_c('div',{staticClass:"label"},[_vm._v(_vm._s(option.label))]),(option.description)?_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(option.description)+" ")]):_vm._e()])],1)}),0)]),(_vm.error)?_c('FormFieldError',{attrs:{"error":_vm.error}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }