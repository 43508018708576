<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="field.label"
    :rules="validationRules"
    :class="{
      'is-disabled': isDisabled,
      [size]: size,
    }"
  >
    <TextField
      :value="value"
      :label="field.settings.general.hideLabel ? '' : field.label"
      transform=""
      :is-mandatory="!_isReadonly ? isMandatory : false"
      :is-readonly="_isReadonly"
      :is-disabled="isDisabled"
      :placeholder="placeholder"
      :tooltip="tooltip"
      :error="errors[0]"
      :highlight="highlight"
      :is-search-field="isSearchField"
      :qr-value="qrValue"
      :is-address-field="isAddressField"
      :address-matching-fields="addressMatchingFields"
      :address-value="addressValue"
      @input="handleInput"
      @verify="$emit('verify')"
      @enter="updateFormSearch(value)"
      @updateFormSearch="updateFormSearch"
      @scanQRCode="scanQRCode"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "ShortTextFieldWrapper",

  components: { ValidationProvider, TextField },

  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: Object,
      required: true,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    highlight: {
      type: String,
      default: "",
    },

    filter: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      fieldChange: true,
    };
  },

  computed: {
    validationRules() {
      const rules = {};

      if (this.isMandatory && !this.isReadonly) {
        rules.required = true;
      }

      if (this.contentRule === "ALPHA") {
        rules.alpha = true;
      }

      if (this.contentRule === "ALPHA_SPACES") {
        rules.alphaSpaces = true;
      }

      if (this.contentRule === "ALPHA_DASH") {
        rules.alphaDash = true;
      }

      if (this.contentRule === "ALPHA_NUMERIC") {
        rules.alphaNum = true;
      }

      if (this.contentRule === "EMAIL") {
        rules.email = true;
      }

      if (this.contentRule === "WEB") {
        rules.url = true;
      }

      const minCharacters = this.field.settings.validation.minimum;
      if (minCharacters) {
        rules.min = minCharacters;
      }

      const maxCharacters = this.field.settings.validation.maximum;
      if (maxCharacters) {
        rules.max = maxCharacters;
      }

      return rules;
    },

    size() {
      return this.field.settings.general.size;
    },

    _isReadonly() {
      return (
        this.isReadonly ||
        this.field.settings.general.visibility === "READ_ONLY"
      );
    },

    isDisabled() {
      return this.field.settings.general.visibility === "DISABLE";
    },

    isMandatory() {
      return this.field.settings.validation.fieldRule === "REQUIRED";
    },

    contentRule() {
      return this.field.settings.validation.contentRule;
    },

    placeholder() {
      return this.field.settings.general.placeholder;
    },

    tooltip() {
      return this.field.settings.general.tooltip;
    },

    isSearchField() {
      return this.field.settings.specific.isSearchField === "YES";
    },

    qrValue() {
      return this.field.settings.specific.qrValue;
    },

    isAddressField() {
      return this.field.settings.specific.isAddressField;
    },

    addressMatchingFields() {
      return this.field.settings.specific.addressMatchingFields;
    },

    addressValue() {
      return this.field.settings.specific.addressValue;
    },
  },

  watch: {
    filter: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.settings.specific.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },

    field: {
      immediate: true,
      // deep: true,
      handler() {
        if (!this.value && this.fieldChange) {
          if (this.field.settings.specific.masterFormParentColumn) {
            this.fieldChange = false;
            this.$emit(
              "getParentColumnValue",
              this.field.settings.specific.masterFormParentColumn
            );
          }
        }
      },
    },
  },

  created() {},

  methods: {
    handleInput(value, address) {
      if (address) {
        const hasMatchingSelectField = this.addressMatchingFields.some(
          (item) => item.selectFieldColumn && item.selectFieldColumn.length > 0
        );
        if (this.isAddressField && hasMatchingSelectField) {
          let addressArray = [];
          if (this.field.settings.specific.addressMatchingFields.length) {
            this.field.settings.specific.addressMatchingFields.forEach(
              (item) => {
                if (item.addressColumn === "CITY") {
                  addressArray.push({
                    selectfield: item.selectFieldColumn,
                    value: address.city,
                  });
                } else if (item.addressColumn === "COUNTRY") {
                  addressArray.push({
                    selectfield: item.selectFieldColumn,
                    value: address.country,
                  });
                } else if (item.addressColumn === "STATE") {
                  addressArray.push({
                    selectfield: item.selectFieldColumn,
                    value: address.state,
                  });
                } else if (item.addressColumn === "POSTALCODE") {
                  addressArray.push({
                    selectfield: item.selectFieldColumn,
                    value: address.pincode,
                  });
                }
                this.$emit("input", address.area);
                this.$emit("address", addressArray);
              }
            );
          }
        }
      } else {
        this.$emit("input", value);
      }
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.settings.specific.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.settings.specific.masterFormColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          if (!this.value || this.field.isManualChange) {
            if (options.length === 1) {
              this.handleInput(options[0]);
            } else {
              this.handleInput("");
            }
            this.$emit("clearFilter");
          }
        } else {
          this.handleInput("");
          this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    updateFormSearch(value) {
      this.$emit("updateFormSearch", value, this.field);
    },

    scanQRCode() {
      this.$emit("scanQRCode", this.field);
    },
  },
};
</script>

<style lang="scss" scoped></style>
