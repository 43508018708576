<template>
  <div id="file-upload-field">
    <div class="row items-center">
      <div class="col-auto">
        <FormFieldLabel
          v-if="label"
          :label="label"
          :is-mandatory="isMandatory"
          :tooltip="tooltip"
          :transform="transform"
        />
      </div>
      <div class="col">
        <q-chip
          v-if="prediction"
          outline
          color="pink"
          size="sm"
          class="q-ml-sm"
          style="margin: 0px 0px 0px 10px"
        >
          <q-avatar
            icon="mdi-auto-fix"
            class="q-mr-sm"
            color="pink"
            text-color="white"
          />
          {{ prediction }}
        </q-chip>
      </div>
      <div v-if="settingsFor !== 'TABLE'" class="col-auto">
        <BaseActionButton
          v-if="!isReadonly"
          v-tooltip.top="'upload from another device'"
          icon="mdi-qrcode-scan"
          class=""
          no-border
          is-flat
          @click="$emit('showQr')"
        />
      </div>
    </div>
    <FormFieldWrapper
      :is-focused="isFocused"
      :is-clearable="hasFiles"
      :is-readonly="isReadonly"
      :is-disabled="isDisabled"
      :has-error="!!error"
      action-icon="eva-cloud-upload-outline"
      class="q-px-sm"
      @clear="handleClear"
      @action="$refs.fileUpload.click()"
    >
      <div class="wrapper">
        <ol v-if="hasFiles" class="files">
          <template v-for="(file, index) in value">
            <li v-if="file.name" :key="index">
              <!-- <template v-if="file.repositoryId">{{ file.fileName }}</template> -->
              <template v-if="file.name">{{ file.name }}</template>
            </li>
          </template>
        </ol>
        <div v-else class="placeholder">{{ placeholder }}</div>

        <input
          ref="fileUpload"
          type="file"
          :multiple="isMultiple"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @input.stop="handleInput"
        />
      </div>
    </FormFieldWrapper>

    <FormFieldError v-if="error" :error="error" />
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldWrapper from "@/components/common/form/field-wrapper/FormFieldWrapper.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { fileToBase64 } from "@/helpers/file-to-base64.js";

export default {
  name: "FileuploadField",

  components: { FormFieldLabel, FormFieldWrapper, FormFieldError },

  props: {
    value: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "Choose File",
    },

    isMandatory: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      default: "",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadonly: {
      type: Boolean,
      default: false,
    },

    error: {
      type: String,
      default: "",
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: () => {},
    },

    prediction: {
      type: String,
      default: "",
    },

    settingsFor: {
      type: String,
      default: "",
    },

    transform: {
      type: String,
      default: "transform",
    },
  },

  data() {
    return {
      isFocused: false,
      files: [],
      fileNew: [],
    };
  },

  computed: {
    hasFiles() {
      if (this.files.length) {
        return !!this.files.length;
      } else if (this.value && this.value.length && !this.files.length) {
        let files = this.value.filter((file) => file.name);
        if (files.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (this.value) {
          if (this.value.length) {
            let file = this.value.find((item) => item.name);
            if (!file) {
              this.files = [];
              if (this.$refs.fileUpload) {
                this.$refs.fileUpload.value = null;
              }
            }
            // console.log(this.error, this.value);
            if (!this.error) {
              // this.$emit("upload", this.fileNew);
            }
          } else {
            this.files = [];

            if (this.$refs.fileUpload) {
              this.$refs.fileUpload.value = null;
            }
          }
        } else {
          this.files = [];

          if (this.$refs.fileUpload) {
            this.$refs.fileUpload.value = null;
          }
        }
      },
    },

    error: {
      immediate: true,
      handler() {
        // this.files = [];
        // this.$refs.fileUpload.value = null;
        // this.$emit("input", this.files);
      },
    },
  },

  methods: {
    isTestServer() {
      let origin = location.origin;
      if (
        origin === "http://52.172.32.88" ||
        origin === "https://demo.ezofis.com" ||
        origin === "http://localhost:8080"
      ) {
        return true;
      }
      return false;
    },

    async handleInput(e) {
      let _files = [];
      if (this.field.settings.specific.allowMultipleFiles) {
        if (this.value && this.value.length) {
          _files = this.value;

          this.files = Array.from(e.target.files);
        } else {
          _files = [];
          this.files = Array.from(e.target.files);
        }
      } else {
        if (this.value && this.value.length) {
          _files = this.value;

          this.files = Array.from(e.target.files);
        } else {
          _files = [];
          this.files = Array.from(e.target.files);
        }
      }
      // const filesList = [..._files, ...this.files];

      // console.log(filesList, "this.files");

      // let _files = [];
      for (let file of this.files) {
        if (file.name) {
          const base64String = await fileToBase64(file);

          _files.push({
            name: file.name,
            size: file.size,
            file: base64String,
          });
        }
      }

      // console.log(_files);
      this.fileNew = e.target.files;

      this.$emit("input", _files, e.target.files);
    },

    handleClear() {
      this.files = [];
      this.$refs.fileUpload.value = null;
      this.$emit("input", this.files);
    },
  },
};
</script>

<style lang="scss" scoped>
#file-upload-field {
  .wrapper {
    min-height: 46px;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 8px;

    ol {
      margin: 0px;
      padding-left: 16px;

      li {
        margin: 8px 0px;
      }
    }
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
